@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Parisienne&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Parisienne&display=swap');

.rsvp_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    position: relative;
    justify-content: center;
    padding: 3rem 0 4rem 0;
    font-family: 'Montserrat', sans-serif;
}

.rsvp_heading {
    padding-bottom: 2rem;
    font-size: 1.5rem;
}

.form {
    gap: 16px;
    text-align: left;
    justify-content: center;
    align-items: center;
}

.contact_form {
    width: 100%;
}

input, textarea {
    width: 100%;
    padding: 16px;
    background: white;
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    resize: none;
    color: black;
    margin: 12px 0;
    font-family: 'Montserrat', sans-serif;
}

.select_container {
    margin: 12px 0;
}

input:focus {
    background: white;
    border: 1px solid;
    border-color: #dbdbdb;
    border-radius: 4px;
}

textarea:focus {
    background: white;
    border: 1px solid;
}

.guest-btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 32px 0;
    gap: 3rem;
}

.btn_container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 16px;
}

.guest_btn {
    width: max-content;
    display: inline-block;
    color: white;
    padding: 12px 19.2px;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid;
    transition: var(--transition);
    background: #454545;
    font-size: larger;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
    border-radius: 0.5rem;
}

.guest_btn.plus:hover {
    background: #79AC78;
    color: white;
}

.guest_btn.minus:hover {
    background: #EF4040;
    color: white;
}

.plus_icon {
    padding: 5px 5px 0 0;
}

.btn {
    width: max-content;
    display: inline-block;
    color: white;
    padding: 12px 19.2px;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid;
    transition: var(--transition);
    background: #454545;
    font-size: larger;
    font-family: 'Montserrat', sans-serif;
    border-radius: 0.5rem;
}

.btn:hover {
    background: #79AC78;
    color: white;
}

.submit_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    padding: 3rem 0;
    width: 100%;
    margin-top: 86.88px;
}

.check {
    color: #79AC78;
    font-size: 4rem;
    font-weight: 800;
}

.check_message {
    font-family: 'Montserrat', sans-serif;
    line-height: 2rem;
    font-size: 0.9rem;
}

.submit_home {
    text-decoration: none;
    width: max-content;
    display: inline-block;
    color: white;
    padding: 12px 19.2px;
    border-radius: 0;
    cursor: pointer;
    border: 1px solid;
    transition: var(--transition);
    background: #454545;
    font-size: larger;
    font-family: 'Montserrat', sans-serif;
}

.submit_home:hover {
    background: #79AC78;
    color: white;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    form {
        width: 80%;
    }

    .submit_container {
        margin-top: 0;
        min-height: 70vh;
    }
}

/* MEDIA QUERIES (SMALL DEVICES) */
@media screen and (max-width: 600px) {
    .rsvp_container {
        padding: 3rem 1rem 4rem 1rem;
    }

    form {
        width: 80%;
    }

    .rsvp_container {
        margin-top: 86.88px;
    }

    .submit_container {
        margin-top: 0;
        min-height: 100vh;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Parisienne&display=swap');

@font-face {
    font-family: 'Alex Brush';
    src: url('../../assets/AlexBrush-Regular.ttf');
}

.details_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 86.88px;
}

.image_container .details_image {
    width: 100%;
    border-radius: 1rem;
}

.details_message {
    width: 100%;
    font-size: 1.2rem;
    text-align: center;
    line-height: 2rem;
}

.details_dates {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.event {
    display: flex;
    width: 50%;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 0.9rem;
}

.heading {
    font-family: 'Alex Brush';
    font-size: 3rem;
    font-weight: 200;
    margin-top: 0;
}

.time {
    margin: 0;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.4rem;
    font-style: italic;
    color: #9BA4B5;
    font-weight: 500;
}

.details_address {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;

}

.address {
    line-height: 2rem;
    font-size: 1.2rem;
    margin-top: 0;
    font-weight: 300;
}

strong {
    font-weight: 500;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 600px) {
    .image_container .details_image {
        width: 90%;
    }

    .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .event {
        width: 80%;
    }

    .details_container {
        padding: 20px 0 50px 0;
    }

    .details_dates {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .details_message {
        padding: 2rem;
    }
}
.photos_container {
    display: flex;
    align-items: center;
    justify-content: center;
}

.gallery_container {
    max-width: 600px;
    margin: 2rem 0;
    display: grid;
    grid-template-columns: repeat(3, auto);
    gap: 1rem;
    align-items: center;
    justify-content: center;
}

.gallery_image {
    width: 100%;
    height: 15rem;
    cursor: pointer;
}

.gallery_image img {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 600px) {
    .photos_container {
        margin-top: 86.88px;
    }

    .gallery_container {
        grid-template-columns: repeat(2, auto);
        padding: 0 1rem;
    }
}
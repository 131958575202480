* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url("../src/assets/trialbackground.jpg");

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.canvas {
  max-width: 800px;
  margin: 100px auto;
  padding: 100px;
  background: white;
  border-style: solid;
  border-color: #eee;
  border-width: 2px;
  border-radius: 1rem;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
  .canvas {
    margin: 2rem auto;
    padding: 2rem;
  }
}

@media screen and (max-width: 600px) {
  .canvas {
    margin: 0;
    padding: 0;
  }
}
.faq_container {
    display: flex;
    width: 100%;
    margin-top: 86.88px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 63px;
}

.faq_inner {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 50px;
}

.faq_header {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 32px;
}

.faq_header h1 {
    display: flex;
    width: 100%;
    flex-direction: column;
    text-align: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.8rem;
}

.faq_list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
}

.faq {
    cursor: pointer;
    background: #D2E3C8;
    border-radius: 1rem;
}

.question {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    flex-direction: row;
    align-items: center;
    gap: 32px;
    justify-content: space-between;
    font-family: 'Montserrat', sans-serif;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: 0.5px;
    max-height: 124px;
}

.answer {
    display: flex;
    width: 100%;
    padding: 0 1rem;
    align-items: flex-start;
    background: var(--color-white);
    color: #000;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.5px;
    max-height: 0;
    overflow: hidden;
    transition: all 0.1s ease-in;
}

.active.answer {
    max-height: 1000px;
    transition: all 0.1s ease-in;
}

.language_toggle {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}

.language_toggle p {
    color: #414141;
    font-family: 'Montserrat', sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
}

.toggle_container {
    position: relative;
    top: 0;
    left: 0;
    width: 81.667px;
    height: 42px;
    background: #f1f1f1;
    border-radius: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 2px solid #ececec;
    transition: 0.3s;
}

.toggle_container.active {
    background: #79AC78;
}

.toggle_container.active .toggle_btn {
    left: 41px;
    box-shadow: none;
}

.toggle_btn {
    position: relative;
    top: 0;
    left: 1px;
    width: 35px;
    height: 35px;
    background: #fefefe;
    border-radius: 30px;
    cursor: pointer;
    z-index: 3;
    box-shadow: 0px 0px 10px 1px #cdcdcd;
    transition: 0.3s;
}

.english.active {
    color: #79AC78;
    text-decoration: underline;
    font-weight: 600;
}

.viet.active {
    color: #79AC78;
    text-decoration: underline;
    font-weight: 600;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {
    .faq_header h1 {
        font-size: 1.8rem;
        line-height: 2.5rem;
    }

    .faq_header {
        width: 80%;
    }

    .faq_list {
        width: 85%;
        padding-bottom: 3rem;
    }

    .question {
        padding: 2% 5%;
        max-height: none;
        font-size: 1.2rem;
    }

    .answer {
        font-size: 20px;
        line-height: 1.7rem;
    }

    .active.answer {
        padding: 0 5% 2% 5%;
    }

    .faq_header h1 {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 600px) {
    .question {
        font-size: 0.8rem;
    }

    .active.answer {
        padding: 0 5% 32px 5%;
    }

    .faq_list {
        width: 90%;
        gap: 1rem;
    }

    .question {
        line-height: 24px;
    }

    .answer {
        font-size: 12px;
        line-height: 1.3rem;
    }

    .faq_inner {
        padding-bottom: 3rem;
    }
}
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&family=Parisienne&display=swap');

.home_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    gap: 50px;
    margin-top: 86.88px;
}

.image_container {
    width: 100%;
    justify-content: center;
    align-items: center;
}

.image_container .home_image {
    width: 100%;
    border-radius: 1rem;
}

.invite_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 20px;
    font-family: 'Montserrat', sans-serif;
}

.invite_container hr {
    width: 70%;
    height: 2px;
    background-color: #EEEDED;
    border: none;
}

.invite_container h4 {
    font-family: 'Parisienne';
    font-size: 1.7rem;
    font-weight: 400;
    margin: 0;
}

.home_details {
    font-size: 1rem;
    font-weight: 600;
}

.name {
    font-size: 1.3rem;
    font-weight: 600;
}

.home_info {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
    line-height: 1.5rem;
    font-family: 'Montserrat', sans-serif;
    font-size: 1.1rem;
}

.home_info p {
    margin-top: 0;
}

/* MEDIA QUERIES (MEDIUM DEVICES) */
@media screen and (max-width: 1024px) {

}

@media screen and (max-width: 600px) {
    .image_container .home_image {
        width: 90%;
    }

    .image_container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .home_container {
        padding: 20px 0 50px 0;
    }

    .home_info {
        width: 80%;
    }
}